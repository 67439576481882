import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";


export default function PopUp(props: any) {



    const location = useLocation();
    const params = new URLSearchParams(location.search);


    let dato = params.get('nombre');
    let direccion: any = params.get('direccion');
    console.log(params.get('nombre')); // imprime el valor del parámetro "nombre"


    async function pop() {


        // Asigna el valor que deseas mostrar

        Swal.fire({
            title: "Correcto",
            html: "Ticket correctamente generado, con el número <b>" + dato + "</b>",
            text: "Text",
            icon: "success",
        }).then(async()=> {
            // Redireccionar al usuario
           await redirect();
            console.log('Se hizo clic en el botón Aceptar.');
        });


    }

    async function redirect(){

        window.location.href = direccion;
    }

    useEffect(() => {
    
        pop();
    
      return () => {
      }
    }, [])
    

    return (
        <>
          

         

        </>
    )


}