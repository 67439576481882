import { BrowserRouter, Switch, Route } from "react-router-dom";

import View404 from "../VIEWS/404";
import DominioIncorrecto from "../VIEWS/DominioIncorrecto";
// PRUEBA 
import Pruebas from "../VIEWS/Pruebas/prueba";

// Bulto Ciego
import BultoCiego from "../../src/VIEWS/00_BultoCiego/01_Buqueda_BC";

import Pop from "../VIEWS/00_BultoCiego/PopUp/popup"



import App from "../App";

export default function RoutesMain() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={App} path="/" exact />

        {/*  Bulto Ciego */}
        <Route component={BultoCiego} path="/Main" exact />


        {/* PopUp */}
        <Route component={Pop} path="/Popup" exact />


        {/*  Prueba */}
        <Route component={Pruebas} path="/Prueba" exact />

        {/* DOMINIO INCORRECTO */}
        <Route component={DominioIncorrecto} path="/InValidDomain" exact />

        {/*  ERROR */}
        <Route component={View404} path="*" exact />

      </Switch>
    </BrowserRouter>
  );
}
