import moment from "moment";
import Tracking from "../../../API/MICROSERVICES/tracking/tracking";
import UbicFisica from "../../../API/MICROSERVICES/ubicacacionFisica/ubicacionFisica";
import { InvierteFecha } from "../../../SHARED/Formatos";
import Topdesk from "../../../API/MICROSERVICES/topdesk/topdesk";
import tracking from "../../../API/MICROSERVICES/tracking/tracking";
import Rezago from "../../../API/MICROSERVICES/rezago/rezago";
import ConsultaTopdesk from "../../../API/MICROSERVICES/consulta_topdesk/consultaTopdesk";

export function abrirFormulario(url: string) {
    window.open(url, "_self");
}


export async function TraeUbicacionFisica(enca: string, epro: number, fecha: string) {

    let Request = {
        encacodigobarra: enca,
        eprocodigo: epro,
        fechacompromiso: fecha
    }




    let respuesta = await UbicFisica.ObtenerUbicacionFisica(Request).then((resp) => {



        return resp.data;
    });


    return respuesta;

}

export async function TraeAgencias() {

    let agencias: any[] = await Topdesk.AgenciasALL().then(async (resp: any) => { return resp.data });

    return agencias;
}

export async function TraeColores() {

    let agencias: any[] = await Topdesk.coloresALL().then(async (resp: any) => { return resp.data });

    return agencias;
}

export async function TraeTipoTicket() {

    let data = await Topdesk.TipoTicketALL().then((resp) => {
        return resp.data;
    });

    return data;

}


export async function TrackingDetalle(of: any) {

    let ordenFlete = {
        of: of
    }

    let DATA = await tracking.detalle(ordenFlete).then(async (resp: any) => {

        return resp

    });

    return DATA;

}



export async function CalculoFechaEnvioRezago(ordenFlete: any) {



    // ordenFlete = "959292316";

    let orden = {
        of: ordenFlete
    }


    try {


        let FechaCreaOF_FULL: string = await Tracking.detalle(orden).then((resp) => {
            return resp.data.ordenFlete.fechaEmision;
        });

        let respuesta;

        if (FechaCreaOF_FULL != null) {

            let ArrayFechaCreaOF: any[] = FechaCreaOF_FULL.split("T");

            let fechaCreaOri: string = ArrayFechaCreaOF[0];

            const date = moment(fechaCreaOri); // Fecha inicial
            const newDate = date.add(20, 'days').format('YYYY-MM-DD');

            let FechaCreacion = InvierteFecha(fechaCreaOri);

            let FechaFinal = InvierteFecha(newDate);

            respuesta = {

                creacion: FechaCreacion,
                rezago: FechaFinal

            }

        } else {

            respuesta = {

                creacion: "Problemas con OF",
                rezago: "Problemas con OF"

            }

        }


        return respuesta;


    } catch (e) {

        let respuesta = {

            creacion: "Problemas con OF",
            rezago: "Problemas con OF"

        }

        return respuesta;


    }


}


export async function traeTicketResuelto(modalInfoDto: any) {

    let Datos = await Topdesk.TraeTrickeResuleto(modalInfoDto.ticketRecuperado).then((resp) => {
        return resp.data;
    });

    return Datos;

}


export async function traeRezago(enca: any) {

    let encacodigo = { encacodigobarra: enca };

    let rezago = await Rezago.DatosRezagoBulto(encacodigo).then(async (resp) => {
        return resp.data;
    });

    return rezago

}


export async function traeRezagoOF(ordenflete: any) {
   

    let ordenf = { odflCodigo: ordenflete };

    try{

        
        let rezago = await Rezago.DatosRezagoOrdenFlete(ordenf).then(async (resp) => {
            return resp.data;
        });
        
        
        
        return rezago[0];
    }catch(e){};

}









export async function direcRezago(odflcodigo:any ){

    
    let Query:string = `SELECT MVOF.ODFLCODIGO,MVEN.ENCACODIGOBARRA,NVL(TO_CHAR(MVR.ODFLFECHENTREST,'DD-MM-YYYY'),'-1') AS FECHA_ENTREGA,NVL(TO_CHAR(MVR.NOMIFECHARECEP, 'DD-MM-YYYY'),'-1') AS FECHA_RECEPCION_NOMINA FROM MV_ORDEN_FLETE MVOF LEFT JOIN MV_ENCARGO MVEN ON MVEN.ODFLCODIGO = MVOF.ODFLCODIGO LEFT JOIN MV_REZAGO MVR ON MVR.ENCACODIGO = MVEN.ENCACODIGO WHERE MVOF.ODFLCODIGO = ${odflcodigo}`;


        try{
    
         let DATOS = await ConsultaTopdesk.GetQueryDLS(Query).then((resp:any)=>{    
           return resp;
    
         })


          let respuesta:any[] = []

          DATOS.data.forEach((element:any) => {

            let arr:any[] = (element.replace("[", "").replace("]", "")).split(",");

            let dato = {

                ordenFlete :            arr[0].trim() ,
                encacodigobarra :       arr[1].trim() ,
                fechaEntrega:           arr[2].trim() ,
                fechaRecepcionNomina:   arr[3].trim(),

            }

            respuesta.push(dato);
            
          });

          return respuesta;
    
        }catch(e){};

};




export async function direcEstado(odflcodigo:any ){
    
    let Query:string = `SELECT odflcodigo, eprocodigo FROM MV_ORDEN_FLETE WHERE ODFLCODIGO = ${odflcodigo}`;

    
         let DATOS = await ConsultaTopdesk.GetQueryDLS(Query).then((resp:any)=>{ return resp; });


          let respuesta:any[] = []

          DATOS.data.forEach((element:any) => {

            let arr:any[] = (element.replace("[", "").replace("]", "")).split(",");

            let dato = {

                ordenFlete :            arr[0].trim() ,
                estado:                 arr[1].trim()  
        

            }

            respuesta.push(dato);
            
          });


    

          return respuesta[0];
    

};


