
import React, { useEffect, useState } from "react";

import axios from "axios";



import fetch from 'node-fetch';
import ConsultaTopdesk from "../../API/MICROSERVICES/consulta_topdesk/consultaTopdesk";
import { direcEstado, direcRezago, TrackingDetalle } from "../00_BultoCiego/Services/OperadoresBC";

export default function Pruebas(props: any) {


  useEffect(() => {
    algo();

  }, []);


  async function algo() {

    console.log("algo");


   // let datos = await direcRezago("723778913");


    let datos2 = await TrackingDetalle("723778913");


    let datos = await direcEstado("723778913");







    console.log("datos", datos);

    console.log("datos2", datos2.data.ordenFlete.codigoEstado);


    // let efecha =  "2023-06-11T";
    // let id = "9533b9b8-3083-41c1-ad7d-887fd56edefd";


    // let tipo = await Topdesk.BusquedaTipoTicketFiltrada(id, efecha).then((resp)=>{
    //     console.log(resp);
    // }); 

    // console.log(tipo);


    // FiltroFechasTipoTicket("","");

    //  let Query:string = "SELECT MVOF.ODFLCODIGO,MVEN.ENCACODIGOBARRA,NVL(TO_CHAR(MVR.ODFLFECHENTREST,'DD-MM-YYYY'),'-1') AS FECHA_ENTREGA,NVL(TO_CHAR(MVR.NOMIFECHARECEP, 'DD-MM-YYYY'),'-1') AS FECHA_RECEPCION_NOMINA FROM MV_ORDEN_FLETE MVOF LEFT JOIN MV_ENCARGO MVEN ON MVEN.ODFLCODIGO = MVOF.ODFLCODIGO LEFT JOIN MV_REZAGO MVR ON MVR.ENCACODIGO = MVEN.ENCACODIGO WHERE MVOF.ODFLCODIGO = 723778913 ";

    // // FiltroFechasTipoTicket("","");

    // let Query:string = "SELECT MVOF.ODFLCODIGO FROM MV_ORDEN_FLETE MVOF WHERE MVOF.ODFLCODIGO = 723778913";



    //  let respuesta = await ConsultaTopdesk.GetQueryDLS(Query).then((resp:any)=>{

    //    return resp;

    //   })

    //   console.log("Respuesta", respuesta);

      

    // const axios = require('axios');
    // let data = JSON.stringify({
    //   "Query": "SELECT MVOF.ODFLCODIGO,MVEN.ENCACODIGOBARRA,NVL(TO_CHAR(MVR.ODFLFECHENTREST,'DD-MM-YYYY'),'-1') AS FECHA_ENTREGA,NVL(TO_CHAR(MVR.NOMIFECHARECEP, 'DD-MM-YYYY'),'-1') AS FECHA_RECEPCION_NOMINA FROM MV_ORDEN_FLETE MVOF LEFT JOIN MV_ENCARGO MVEN ON MVEN.ODFLCODIGO = MVOF.ODFLCODIGO LEFT JOIN MV_REZAGO MVR ON MVR.ENCACODIGO = MVEN.ENCACODIGO WHERE MVOF.ODFLCODIGO = 723778913"
    // });

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'https://topdesk-consultaqa.starken.cl/TopDeskConsulta/ConsultaDLS_BO/getQuery',
    //   headers: {
    //     'Access-Controll-Allow-Origin': '*',
    //     'Content-type': 'application/json',
    //     'Authorization': 'Basic dGRrX3BydWViYTp0ZGsyMDIz'
    //   },
    //   data: data
    // };

    // axios.request(config)
    //   .then((response: any) => {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch((error: any) => {
    //     console.log(error);
    //   });



  //  const response = await fetch('https://topdesk-consultaqa.starken.cl/TopDeskConsulta/ConsultaDLS_BO/getQuery', {method: 'POST', body: 'a=1'});


  }





  return (
    <>
      <h1>Hola Mundo</h1>
      <h1>Chao Mundo</h1>

    </>
  )

}

