import moment from "moment";
import { ENTORNO, ESTADOS, VALIDACIONES } from "../../../API/CONSTANTS/constants";
import Topdesk from "../../../API/MICROSERVICES/topdesk/topdesk";
import Rezago from "../../../API/MICROSERVICES/rezago/rezago";
import UbicFisica from "../../../API/MICROSERVICES/ubicacacionFisica/ubicacionFisica";


export let mensa: string;

export async function test(mensaje: string) {
    let texto: string = "Imprimo un mensaje " + mensaje;

    return texto;
}



export async function validarOf(resultadoOrdenFlete: any) {

    let final = false;

    if (resultadoOrdenFlete == undefined) {

    } else {
        if (resultadoOrdenFlete.data.ordenFlete.of != null) {
            final = true;
        }
    }

    return final;
}


export async function validarEstado(resultadoOrdenFlete: any) {

 

    let final = false;

    let estado = resultadoOrdenFlete.data.ordenFlete.codigoEstado;

    let ENTREGADO = ESTADOS.ENTREGADO;
    let ANULADO = ESTADOS.ANULADO;
    let CERRADO_EXCEPCION = ESTADOS.CERRADO_EXCEPCION;
    let REMATADO = ESTADOS.REMATADO;
    let REDESTINADO = ESTADOS.REDESTINADO;
    let DECOMISADO = ESTADOS.DECOMISADO;
    let DEVUELTO = ESTADOS.DEVUELTO;
    let REZAGO_PARCIAL = ESTADOS.REZAGO_PARCIAL;

    if (resultadoOrdenFlete == undefined) {

    } else {

        if (estado != null) {

            if (estado == ENTREGADO) {
            } else if (estado == ANULADO) {
            } else if (estado == CERRADO_EXCEPCION) {
            } else if (estado == REMATADO) {
            } else if (estado == REDESTINADO) {
            } else if (estado == DECOMISADO) {
            } else if (estado == DEVUELTO) {
            } else if (estado == REZAGO_PARCIAL) {
            } else {
                final = true;
            }
        }
    }

    let estadoFinal = await  interpreteESTADO(estado);

 

   let  respuesta:{} = {
        booleano : final,
        mensaje : estadoFinal
    }


 

    return respuesta;
}


export async function interpreteESTADO(codigoEstado: number){

    let mensaje: string = "";

    let ENTREGADO = ESTADOS.ENTREGADO;
    let ANULADO = ESTADOS.ANULADO;
    let CERRADO_EXCEPCION = ESTADOS.CERRADO_EXCEPCION;
    let REMATADO = ESTADOS.REMATADO;
    let REDESTINADO = ESTADOS.REDESTINADO;
    let DECOMISADO = ESTADOS.DECOMISADO;
    let DEVUELTO = ESTADOS.DEVUELTO;
    let REZAGO_PARCIAL = ESTADOS.REZAGO_PARCIAL;

    if (codigoEstado == ENTREGADO) {

        mensaje = "ESTADO ENTREGADO";
    };
    
    if (codigoEstado == ANULADO) {
    
        mensaje = "ESTADO ANULADO";
    };
    
    if (codigoEstado == CERRADO_EXCEPCION) {

        mensaje = "ESTADO CERRADO CON EXCEPCION";
    };
    
    if (codigoEstado == REMATADO) {

        mensaje = "ESTADO REMATADO";
    };
    
    if (codigoEstado == REDESTINADO) {

        mensaje = "ESTADO REDESTINADO";
    };
    
    if (codigoEstado == DECOMISADO) {

        mensaje = "ESTADO DECOMISADO";
    };
    
    if (codigoEstado == DEVUELTO) {

        mensaje = "ESTADO DEVUELTO";
    };
    
    if (codigoEstado == REZAGO_PARCIAL) {
        mensaje = "ESTADO REZAGO PARCIAL";
    }




    return mensaje;



}


export async function validarTopdeskOfOriginal(resultadoOrdenFlete: any, tk: any) {


   

    // Calcular 6 meses desde la fecha de consulta para limitar los resultados.

    let fechaActual = new Date();

    let MesesAntes = Number(VALIDACIONES.TDK_MESES_VALIDACION);

    let fechaAnterior = moment().add('month', -MesesAntes).format('YYYY-MM-DD');



    try {

        let incidenteResponse = await Topdesk.ValidaIncidenteTicket(resultadoOrdenFlete, fechaAnterior, tk).then((resp) => {

            return resp;
        })








        let incidente = incidenteResponse.data;

        let llavePaso;

        if (incidenteResponse.status !== 204) {

            if (incidente != undefined) {

                if (incidente != null) {

                    // if (incidenteResponse.data[0].number === tk) {
         



                        if (ENTORNO.ENTORNO == "PRODUCCION" || ENTORNO.ENTORNO == "LOCAL_PRD") {

                            let dato: any | null | undefined = incidente[0].optionalFields2.searchlist3?.id;

                            llavePaso = (dato != null || dato != undefined) ? dato : "null";

                        } else {

                            let dato: any | null | undefined = incidente[0].optionalFields1.searchlist5?.id;

                            llavePaso = (dato != null || dato != undefined) ? dato : "null";

                        }

                        if (incidente != "" && llavePaso == "null") {
                            mensa = "" + incidente[0].number;
                        }

                    }
                // }
            }
        }

        let validacionIncidente = incidente.length;

        let respuesta1;
        let respuesta2;
        let respuesta3;
        let respuesta4;


        if (validacionIncidente == 0) {

            respuesta1 = {
                mensaje: "",
                validador: true,
            }



        } else {
            respuesta1 = {
                mensaje: mensa,
                validador: false,
            }

        }



        let cambioResponse = await Topdesk.ValidaCambioTicket(resultadoOrdenFlete, fechaAnterior, tk).then((resp) => {



            return resp;
        })


    

        let cambio = cambioResponse.data.results;





        if (cambioResponse.status !== 204) {
            if (cambio != undefined) {
                if (cambio != null) {
                    if (cambio != "") {
                        mensa = "" + cambio[0].number;
                    }
                }
            }
        }


        // let validacionIncidente = incidente.length;
        let validacionCambio = cambio.length;

        if (validacionCambio == 0) {


            respuesta2 = {
                mensaje: "",
                validador: true,
            }



        } else {
            respuesta2 = {
                mensaje: mensa,
                validador: false,
            }

        }


        respuesta3 = {
            mensaje: respuesta1.mensaje + respuesta2.mensaje,
            validador: false,
        }


        if (validacionIncidente > 0) {

            return respuesta1;

        } else if (validacionCambio > 0) {

            return respuesta2;

        } else if (respuesta3.mensaje.length > 0) {

            respuesta3 = {
                mensaje: respuesta1.mensaje + " / " + respuesta2.mensaje,
                validador: false,
            }


            return respuesta3;

        } else {

            respuesta4 = {

                mensaje: "",
                validador: true,

            }

            return respuesta4;

        }




    } catch (error) {

        const respuesta = {

            mensaje: "" + error,
            validador: false,

        }

        return respuesta;
    }

}



export async function validarTopdeskOfTicket(resultadoOrdenFlete: any, tk: any) {



    // Calcular 6 meses desde la fecha de consulta para limitar los resultados.

    let fechaActual = new Date();

    let MesesAntes = Number(VALIDACIONES.TDK_MESES_VALIDACION);

    let fechaAnterior = moment().add('month', -MesesAntes).format('YYYY-MM-DD');

    try {

        // let incidenteResponse = await Topdesk.ValidaIncidenteTicket(resultadoOrdenFlete, fechaAnterior, tk).then((resp) => {

        //     return resp;
        // })

        // let incidente = incidenteResponse.data;

        // if (incidenteResponse.status !== 204) {
        //     if (incidente != undefined) {
        //         if (incidente != null) {
        //             if (incidente != "") {
        //                 mensa = "" + incidente[0].number;
        //             }
        //         }
        //     }
        // }


        let cambioResponse = await Topdesk.ValidaCambioTicket(resultadoOrdenFlete, fechaAnterior, tk).then((resp) => {



            return resp;
        })

   


        let cambio = cambioResponse.data.results;





        if (cambioResponse.status !== 204) {
            if (cambio != undefined) {
                if (cambio != null) {
                    if (cambio != "") {
                        mensa = "" + cambio[0].number;
                    }
                }
            }
        }


        // let validacionIncidente = incidente.length;
        let validacionCambio = cambio.length;

        if (validacionCambio == 0) {


            const respuesta = {
                mensaje: "",
                validador: true,
            }

            return respuesta;

        } else {
            const respuesta = {
                mensaje: mensa,
                validador: false,
            }
            return respuesta;
        }


    } catch (error) {

        const respuesta = {

            mensaje: "" + error,
            validador: false,

        }

        return respuesta;
    }

}

export async function DatosRezagoOrdenFlete(resultadoOrdenFlete: any) {
    let respuesta = await Rezago.DatosRezagoOrdenFlete(resultadoOrdenFlete).then(async (resp) => {

        return resp;
    });


    return respuesta;
}

export async function DatosRezagoBulto(encacodigobarra: any) {

    let respuesta = await Rezago.DatosRezagoBulto(encacodigobarra).then(async (resp) => {

        return resp;
    });

    return respuesta;


}

export async function UbicacionFi(encacodigobarra: any) {

    let respuesta = await UbicFisica.ObtenerUbicacionFisica(encacodigobarra).then(async (resp) => {


        return resp.data;
    });

    return respuesta;

}
