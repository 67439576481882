import { ENTORNO, MICROSERVICIOS, REQUEST_TIMEOUT } from "../../CONSTANTS/constants";
import { RequestResponse } from "../../DTO/requestResponse";

const axios = require('axios');

export default class ConsultaTopdesk {

  // ConsultaTopdesk.GetQueryDLS
  static async GetQueryDLS(query: string): Promise<RequestResponse> {

    let direccion = "ConsultaDLS_BO/getQuery";
    let data: any = await GetData(direccion, query);

    let respuesta: RequestResponse = {
      status: data.status,
      description: data.description,
      error: data.error,
      data: data.data
    }

    return respuesta;
  }

}


async function GetData(direccion: any, Query: any) {

  let auth;


  if (ENTORNO.ENTORNO == "PRODUCCION" || ENTORNO.ENTORNO == "LOCAL_PRD") {
    auth = "Basic dGRrOnRkazIwMjE=";
  } else {
    auth = "Basic dGRrX3BydWViYTp0ZGsyMDIz";
  }


  let data: any = JSON.stringify({
    "Query": Query
  });

  let config: any = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      url: MICROSERVICIOS.CONSULTA_TOPDESK + direccion,
                      headers: {
                        'Access-Controll-Allow-Origin': '*',
                        'Content-type': 'application/json',
                        'Authorization': auth
                      },
                      data: data
  };

  return new Promise(async (resolve) => {

    setTimeout(() => {
                resolve({
                  status: -1,
                  description: "Supero el tiempo de espera",
                  error: "Tiempo de espera superado",
                });
    }, 1000 * REQUEST_TIMEOUT);
    
    try {

      let respuesta: any = await axios.request(config)
        .then((response: any) => {
                            return response;
        })



                            resolve({
                              status: respuesta.status,
                              description: respuesta.statusText,
                              data: respuesta.data,
                            });

    } catch (error: any) {

                            if (error) {

                              resolve({
                                status: -1,
                                description: "No fue posible obtener información, por favor reintente más tarde",
                                data: null,
                                error: "No fue posible obtener información, por favor reintente más tarde",
                              });

                            } else {
                              
                              resolve({
                                status: error.response.status,
                                description: error.response.data.error,
                                data: error.response.data,
                                error: error.response,
                              });
                            }
    }
  });

}
